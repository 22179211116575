<template>
    <div style="height: 500px;">
        <div style="width: 30%;height: 100%;float: left;">
            <el-form :label-position="labelPosition" label-width="80px" >
                <el-form-item label="卡号：">
                    <el-input v-model="cardId"></el-input>
                </el-form-item>
                <el-form-item label="预留手机号：">
                    <el-input v-model="mobile"></el-input>
                </el-form-item>
                <div style="width: 500px;height: 80px;">
                    <div style="width: 200px;float: left;">
                        <el-form-item label="验证码">
                            <el-input  v-model="code"></el-input>

                        </el-form-item>
                    </div>
                    <div style="width: 200px;float: left;margin-top: 5px;margin-left: 10px;">
                        <el-button @click="spendCode" size="mini" round>发送验证码</el-button>
                    </div>

                </div>
                <el-form-item>
                    <el-button type="primary" @click="onSubmit">立即绑定</el-button>
                </el-form-item>

            </el-form>
        </div>
        <div style="width: 65%;height: 100%;float: left;margin-left: 50px;">
            <template>
                <el-table
                        :data="tableData"
                        style="width: 100%"
                        max-height="250">
                    <el-table-column
                            fixed
                            prop="createTime"
                            label="日期"
                            width="160">
                    </el-table-column>
                    <el-table-column
                            prop="bankName"
                            label="所属银行"
                            width="120">
                    </el-table-column>
                    <el-table-column
                            prop="cardId"
                            label="银行卡号"
                            width="220">
                    </el-table-column>
                    <el-table-column
                            prop="telNo"
                            label="预留手机号"
                            width="120">
                    </el-table-column>
                    <el-table-column
                            prop="isSettleAccount"
                            label="结算卡"
                            width="120">
                    </el-table-column>
                    <el-table-column
                            fixed="right"
                            label="操作"
                            width="240">
                        <template slot-scope="scope">
                            <el-button @click.native.prevent="deleteRow(scope.$index, tableData)" type="text" size="small" style="color: red;">
                                一键解绑
                            </el-button>
                            <el-button style="color: #F69403;" v-if="tableData[scope.$index].settleAccountId == ''" @click="bindSettleAccount(scope.$index, tableData)" type="text" size="small">绑定为结算卡</el-button>
                            <el-button style="color: red;" v-if="tableData[scope.$index].settleAccountId != ''" @click="deleteSettleAccount(scope.$index, tableData)" type="text" size="small">解绑结算卡</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </template>
        </div>
    </div>
</template>

<script>
    export default {
        name: "bind_bank_car",
        data() {
            return {
                labelPosition: 'right',
                cardId:'',
                mobile:'',
                code:'',
                applyId:'',
                tableData: []
            }
        },
        mounted() {
            this.getAdaPayBanCardList();
        },
        methods:{
            //获取绑卡列表
            getAdaPayBanCardList(){
                var that = this;
                var params = {};
                that.newApi.getAdaPayBanCardList(params).then((res) => {
                    if(res.isSuccess == 1){
                        that.tableData = res.data;
                    }
                })
            },
            //解绑银行卡
            deleteRow(index, rows) {
                var that = this;
                that.utils.confirm("解除当前银行卡？",function () {
                    var params = {};
                    params.applyId = rows[index].applyId;
                    that.newApi.rescindBankCardObj(params).then((res) => {
                        if(res.isSuccess == 1){
                            that.utils.sus(res.data);
                            rows.splice(index, 1);
                        }
                    })
                })

            },
            //发送验证码
            spendCode(){
                var that = this;
                var params = {};
                params.cardId = this.cardId;
                params.mobile = this.mobile;
                that.newApi.alpplyBankCard(params).then((res) => {
                    if(res.isSuccess == 1){
                        that.utils.sus("验证码发送成功");
                        that.applyId = res.data;
                    }
                })
            },
            //绑定银行卡
            onSubmit(){
                var that = this;
                var params = {};
                params.cardId = this.cardId;
                params.mobile = this.mobile;
                params.applyId = that.applyId;
                params.code = that.code;
                that.newApi.alpplyBankCardDetermine(params).then((res) => {
                    if(res.isSuccess == 1){
                        that.utils.sus(res.data);
                        that.getAdaPayBanCardList();
                    }
                })
            },

            //绑定为结算卡
            bindSettleAccount(index,rows){
                var that = this;
                that.utils.confirm("用于对用户分账、提现金额的结算，目前仅支持绑定银行卡结算账户",function () {
                    var params = {};
                    params.cardId = rows[index].cardId;
                    params.bankAcctType = 2;
                    that.newApi.settleAccountUser(params).then((res) => {
                        if(res.isSuccess == 1){
                            that.utils.sus(res.data);
                            that.getAdaPayBanCardList();
                        }
                    })
                })
            },

            //解绑结算卡
            deleteSettleAccount(index,rows){
                var that = this;
                that.utils.confirm("解绑该卡位您的结算账户？",function () {
                    var params = {};
                    params.settleAccountId = rows[index].settleAccountId;
                    that.newApi.deleteSettleAccount(params).then((res) => {
                        if(res.isSuccess == 1){
                            that.utils.sus(res.data);
                            that.getAdaPayBanCardList();
                        }
                    })
                })
            }
        }
    }
</script>

<style scoped>

</style>